$color_1: #444;
$color_2: #fff;
$color_3: #000000;
$orange: #ed022a;
$color_5: white;
$color_6: #bbb;
$color_7: #ddd;
$color_8: #aaa;
$color_9: #222;
$color_10: #FFF;
$color_11: #999;
$font_museo_100: 'museo-sans', 'Museo Sans', 'Museo Sans 100', Arial, sans-serif;
$background_color_1: #efefef;
$border_color_1: transparent transparent transparent rgba(237, 2, 42, 0.9);

.split2 {
    color: $color_1;
}

// signup and login forms

fieldset {
    padding: 0.35em 1.625em 0.75em;

    .form-group {
        margin-top: 8px;
        margin-bottom: 8px;

        .control-label {
            display: block;
        }

        input {
            width: 100%;
            display: block;
        }

        .asterisk_input:after {
            top: 15px;
        }
    }

    .form-actions .btn {
        width: auto;
    }
}

#section-site-map {
    .split2 {
        color: $color_2;
    }
    background: #333;
    min-height: 310px;
    .logicon-info {
        float: left;
        width: 60%;
        h1 {
            padding: 15px 0;
        }
        p {
            width: 40%;
            color: $color_5;
        }
        .bottom-logo {
            display: table-cell;
            vertical-align: middle;
            height: 33px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    ul {
        list-style: none;
        line-height: 1.6;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
    }
    a {
        color: $color_5;
        &:hover {
            color: $orange;
        }
    }
}

.editable-form {
    top: 100px;
    input[type="text"] {
        color: $color_3 !important;
    }
    textarea {
        line-height: 1.6 !important;
    }
}

html {
    overflow-y: scroll;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font_museo_100;
    font-size: 14px;
    color: $color_1;
}

h1 {
    font-weight: bold;
    font-size: 1.5em;
    padding: 0;
    margin: 0;
    color: $orange;
}

h2 {
    font-size: 1.5em;
    margin: 0;
    padding: 15px 0;
}

h3 {
    text-transform: uppercase;
    color: $orange;
    font-size: 16px;
    margin: 0;
    padding: 15px 0;
}

h4 {
    padding: 0;
    margin: 0;
    color: $orange;
    padding: 0 0 15px;
}

h6 {
    padding: 0 0 15px;
    margin: 0;
    font-size: 14px;
}

p {
    margin: 0 0 15px;
    line-height: 1.6;
}

a {
    color: $orange;
    text-decoration: none;
    .more-posts {
        &:hover {
            background: $orange;
            color: $color_5;
        }
    }
    .tag {
        color: $color_1;
    }
}

.site-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px 20px 50px;
    box-sizing: border-box;
}

a.anchor {
    display: block;
    position: relative;
    top: -69px;
    visibility: hidden;
}

.page-container {
    padding-top: 70px;
}

.column-one-third {
    width: 33%;
    float: left;
    padding-left: 50px;
    box-sizing: border-box;
}

.column-one-third-left {
    width: 33%;
    float: left;
    padding-right: 50px;
    box-sizing: border-box;
}

.column-two-thirds {
    width: 66%;
    float: left;
    box-sizing: border-box;
    padding-right: 50px;
    border-right: 1px solid #ccc;
}

.column-two-thirds-right {
    width: 66%;
    float: left;
    box-sizing: border-box;
    padding-left: 50px;
    border-left: 1px solid #ccc;
}

.column-one-half {
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding-right: 50px;
}

.column-one-quarter {
    width: 25%;
    float: left;
    box-sizing: border-box;
    padding-right: 2.5%;
}

.site-header {
    height: 70px;
    position: fixed;
    z-index: 100;
    background: white;
    width: 100%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.header-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    display: table;
    height: 100%;
    width: 100%;
    h1 {
        display: table-cell;
        vertical-align: middle;
    }
    .top-logo {
        display: table-cell;
        vertical-align: middle;
        height: 33px;
        margin-top: 20px;
    }
}

#menu {
    display: table-cell;
    vertical-align: middle;
    ul {
        list-style: none;
        margin: 0;
        padding: 0 0 0 10px;
        float: right;
    }
    li {
        float: left;
        padding: 0 0px;
        a {
            color: $color_1;
            padding: 24px 10px;
            transition: background 0.4s;
            border-bottom: 4px solid transparent;
            &:hover {
                color: $orange;
                border-bottom: 4px solid $orange;
            }
        }
    }
}

#jsddm {
    margin: 0;
    padding: 0;
    li {
        a {
            display: block;
        }
        ul {
            margin: 0;
            padding: 0;
            position: absolute;
            visibility: hidden;
            border-top: 4px solid $orange;
            margin-top: -4px;
            li {
                float: none;
                display: inline;
                a {
                    width: auto;
                    background: rgba(0, 0, 0, 0.8);
                    color: $color_5;
                    border-bottom: 1px solid #fff;
                    font-size: 12px;
                    padding: 10px;
                    &:hover {
                        background: $orange !important;
                        color: $color_5 !important;
                        border-bottom: 1px solid white;
                    }
                }
                &:last-child {
                    a {
                        border-bottom: none;
                        &:hover {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}

.menu-mobile-container {
    display: none;
}

#section-slideshow {
    padding-top: 70px;
}

.slide-body {
    .caption.header {
        top: 10% !important;
    }
}

.img_wrapper.loaded {
    img {
        opacity: 1;
    }
}

.img_wrapper {
    img {
        opacity: 0;
        -webkit-transition: opacity 150ms;
        -moz-transition: opacity 150ms;
        -ms-transition: opacity 150ms;
        transition: opacity 150ms;
    }
}

.search-input {
    border: 2px solid #ccc;
    border-right: none;
    background: #E6E6E6;
    padding: 5px 5px 5px 20px;
    font-style: italic;
    float: left;
    width: 60%;
    box-sizing: border-box;
}

.search {
    .fa {
        color: $color_5;
        background: $orange;
        font-size: 16px;
        width: 32px;
        padding: 7px 0 9px;
        text-align: center;
    }
    padding-bottom: 30px;
}

input {
    &:focus {
        outline: none;
    }
}

.recent-posts {
    list-style: none;
    font-weight: bold;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;
    li {
        padding: 16px;
        margin: 0;
        &:nth-child(odd) {
            background: #E6E6E6;
            border-left: 10px solid $orange;
        }
        &:nth-child(even) {
            background: #f2f2f2;
            border-left: 10px solid #e6e6e6;
        }
    }
    a {
        color: $color_1;
        &:hover {
            color: $orange;
        }
    }
}

.archive {
    list-style: none;
    font-weight: bold;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;
    li {
        padding: 16px;
        margin: 0;
        &:nth-child(odd) {
            background: #E6E6E6;
            border-left: 10px solid $orange;
        }
        &:nth-child(even) {
            background: #f2f2f2;
            border-left: 10px solid #e6e6e6;
        }
    }
    a {
        color: $color_1;
        &:hover {
            color: $orange;
        }
    }
}

.categories {
    list-style: none;
    font-weight: bold;
    margin: 0;
    padding: 0;
    padding-bottom: 30px;
    li {
        padding: 16px;
        margin: 0;
        &:nth-child(odd) {
            background: #E6E6E6;
            border-left: 10px solid $orange;
        }
        &:nth-child(even) {
            background: #f2f2f2;
            border-left: 10px solid #e6e6e6;
        }
    }
    a {
        color: $color_1;
        &:hover {
            color: $orange;
        }
    }
}

.post {
    border-bottom: 1px solid #ccc;
    margin-bottom: 30px;
    padding-bottom: 20px;
    &:last-child {
        border: none;
        margin-bottom: 0px;
    }
    h2 {
        padding: 15px 0 8px;
        a {
            color: $color_1;
            &:hover {
                color: $orange;
            }
        }
    }
    img {
        float: left;
        margin: 5px 15px 0 0;
        width: 136px;
    }
}

.more-posts {
    background: #E6E6E6;
    text-align: center;
    padding: 10px;
    transition: 0.4s;
}

.readmore {
    text-align: right;
    padding-right: 10px;
}

.post_meta {
    font-size: 12px;
    color: $color_6;
    padding-bottom: 8px;
}

.time-tags-cats {
    float: left;
    width: 85%;
}

.text-box {
    margin-top: 60px;
    background: #f2f2f2;
    border: 10px solid #e6e6e6;
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    h4 {
        position: absolute;
        background: $orange;
        top: -43px;
        padding: 3px 10px;
        left: -10px;
        color: $color_5;
    }
}

.tag-tip {
    position: relative;
    float: left;
    > i {
        position: absolute;
        width: 0;
        height: 0;
        line-height: 0;
        border: 20px dotted rgba(0, 0, 0, 0);
        border-left: 20px solid #e6e6e6;
        left: 0px;
        top: 0px;
        transition: 0.4s;
    }
}

.tag-body {
    float: left;
    height: 40px;
    line-height: 40px;
    background: #e6e6e6;
    padding: 0 10px;
    transition: 0.4s;
    a {
        color: $color_1;
        &:hover {
            color: $color_2;
        }
    }
}

.tag {
    padding: 0 35px 20px 0;
    float: left;
    font-weight: bold;
    &:hover {
        .tag-body {
            background: $orange;
            color: $color_5;
        }
        .tag-tip {
            > i {
                border-left: 20px solid $orange;
            }
        }
    }
}

#section-features {
    h2 {
        border-bottom: 1px solid #ccc;
        text-align: center;
        margin-bottom: 20px;
    }
    img {
        margin: 0 auto;
        display: block;
    }
    .column-one-third {
        float: none;
        display: inline-block;
        padding-bottom: 20px;
        vertical-align: top;
        text-align: center;
    }
    a {
        .fa {
            font-size: 28px;
            background: #303030;
            color: $color_5;
            width: 73px;
            height: 73px;
            vertical-align: middle;
            line-height: 70px;
            border: 3px solid #fff;
            box-shadow: 0px 0px 5px #888;
            -moz-box-shadow: 0px 0px 5px #888;
            -webkit-box-shadow: 0px 0px 5px #888;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            -khtml-border-radius: 50%;
            transition: all 0.4s ease 0s;
            &:hover {
                border: 3px solid $orange;
            }
        }
    }
}

.feature-container {
    padding: 30px 0 0;

    .column-one-quarter {
        padding: 0 1.25%;
        text-align: center;

        h3 {
            padding-bottom: 5px;
        }
    }
}

.advert {
    width: 73px;
    height: 73px;
    margin: 0 auto;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    border: 3px solid #fff;
    box-shadow: 0px 0px 5px #888;
    -moz-box-shadow: 0px 0px 5px #888;
    -webkit-box-shadow: 0px 0px 5px #888;
    transition: 0.4s;
}

#advert-responsive {
    background: url(img/responsive.png);
    background-position: -7px -7px;
}

#advert-mouse {
    background: url(img/mouse.png);
    background-position: -7px -7px;
}

#advert-control {
    background: url(img/control.png);
    background-position: -7px -7px;
}

#advert-options {
    background: url(img/options.png);
    background-position: -7px -7px;
}

#advert-colors {
    background: url(img/colors.png);
    background-position: -7px -7px;
}

#advert-django {
    background: url(img/django.png);
    background-position: -7px -6px;
}

#section-portfolio {
    h2 {
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
    }
    .container {
        min-height: 350px;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

button {
    display: inline-block;
    padding: 5px 10px;
    background: #444;
    border: 0;
    color: $color_5;
    margin: 5px 5px 0 0;
    cursor: pointer;
    &:focus {
        outline: 0 none;
    }
    & + label {
        margin-left: 1em;
    }
}

.controls {
    margin-top: -5px;
}

.active {
    background: $orange;
}

.container {
    margin-top: 3%;
    text-align: left;
    font-size: 0.1px;
    -webkit-backface-visibility: hidden;
    &:after {
        content: '';
        display: inline-block;
        width: 100%;
    }
    .mix {
        display: inline-block;
        position: relative;
        text-align: left;
        margin-bottom: 3%;
        margin-right: 2.4%;
        display: none;
        vertical-align: top;
        &:hover {
            span.text-content {
                z-index: 1;
                opacity: 0.93;
            }
        }
    }
    .gap {
        display: inline-block;
        position: relative;
    }
}

.col-4 {
    display: inline-block;
    width: 22.5%;
    position: relative;
}

.col-3 {
    display: inline-block;
    position: relative;
    width: 30%;
}

.col-2 {
    display: inline-block;
    position: relative;
    width: 47.5%;
}

#Container {
    img {
        width: 100%;
        display: block;
    }
}

span.text-content {
    background: $orange;
    color: $color_5;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    -webkit-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    transition: opacity 500ms;
    span {
        display: table;
        height: 100%;
        width: 100%;
        span {
            display: table-cell;
            position: relative;
            text-align: center;
            vertical-align: middle;
            font-size: 14px;
            color: $color_5;
            h3 {
                color: $color_5;
                padding-top: 0;
                padding-bottom: 0px;
            }
            i {
                padding-top: 15px;
                width: 22px;
            }
            a {
                color: $color_5;
                &:hover {
                    i {
                        font-size: 24px;
                        padding-top: 0px;
                        transition: font-size 0.4s;
                    }
                }
            }
        }
    }
}

.portfolio-description {
    float: left;
    font-size: initial;
    p {
        font-size: 14px;
    }
}

.portfolio-image-container {
    position: relative;
}

#section-parallax {
    height: 450px;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    h1 {
        color: $color_5;
        text-shadow: 0px 1px 0px #000;
        padding-bottom: 10px;
    }
    p {
        color: $color_5;
        text-shadow: 0px 1px 0px #000;
        font-weight: bold;
    }
}

.parallax-container {
    display: table;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.parallax-text {
    display: table-cell;
    color: $color_5;
    vertical-align: middle;
    text-align: center;
}

#section-parallax-2 {
    min-height: 450px;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    h2 {
        color: $color_5;
        text-align: center;
        text-shadow: 0px 1px 0px #000;
    }
    h4 {
        color: $color_5;
        text-align: center;
        font-weight: bold;
        padding: 0;
        margin: 0;
        text-shadow: 0px 1px 0px #000;
    }
    .circ-img-wrap {
        transition: 0.4s;
        position: absolute;
        top: -50px;
        left: 50%;
        margin-left: -50px;
        padding: 3px;
        background: white;
        width: 100px;
        display: inline-block;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -khtml-border-radius: 50%;
        background-clip: padding-box;
        -webkit-background-clip: padding-box;
        img {
            width: 100%;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            -khtml-border-radius: 50%;
            vertical-align: middle;
        }
    }
    .column-one-third {
        padding-left: 25px;
        padding-right: 25px;
    }
    h3 {
        position: relative;
        z-index: 2;
    }
}

.testimonial-container {
    background: white;
    position: relative;
    margin-top: 75px;
    text-align: center;
    padding: 50px 15px 15px;
}

.quote {
    font-family: $font_museo_100;
    font-size: 140px;
    top: -20px;
    left: -5px;
    position: absolute;
    color: $color_7;
    font-weight: normal;
    z-index: -1;
}

#section-contact {
    background: #E6E6E6;
    line-height: 1.6;
    h2 {
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
    }
    .column-two-thirds {
        h3 {
            padding-top: 0;
        }
    }
    input {
        display: block;
        border: 2px solid #ccc;
        background: #fff;
        padding: 5px 5px 5px 5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    textarea {
        width: 100%;
        max-width: 100%;
        border: 2px solid #ccc;
        height: 100px;
        margin-bottom: 10px;
        padding: 5px 5px 5px 5px;
        box-sizing: border-box;
    }
    .send-button {
        border: none;
        cursor: pointer;
        color: $color_5;
        background: $orange;
        text-align: center;
        width: 60px;
        float: right;
    }
    .fa {
        width: 20px;
    }
}

.input_id_name {
    width: 48%;
    margin-right: 4%;
    display: inline-block;
}

.input_id_email {
    // width: 47%;
    // display: inline-block;
}

.social-fonts-container {
    padding-bottom: 30px;
    a {
        color: $color_1;
    }
}

.contact-section-info {
    a {
        color: $color_1;
    }
}

.errorlist {
    list-style: none;
    position: absolute;
    li {
        position: absolute;
        background: rgba(195, 81, 47, 0.9);
        color: $color_5;
        left: -135px;
        width: 120px;
        padding: 5px !important;
        top: 0px;
    }
    .tag-tip {
        > i {
            border-width: 14px;
            border-color: $border_color_1;
            left: 115px;
            top: -20px;
        }
    }
}

.form-errors {
    display: inline;
}

.errornote {
    padding-left: 15px;
    font-size: 14px;
    text-transform: none;
    color: $color_1;
}

.popular-pages {
    float: left;
    width: 20%;
    box-sizing: border-box;
    padding-right: 3%;
    color: $color_5;
    h3 {
        padding-top: 21px;
    }
}

.about-us {
    float: left;
    width: 20%;
    box-sizing: border-box;
    padding-right: 3%;
    color: $color_5;
    h3 {
        padding-top: 21px;
    }
}

.contact-info {
    float: left;
    width: 20%;
    box-sizing: border-box;
    padding-right: 3%;
    color: $color_5;
    h3 {
        padding-top: 21px;
    }
    .fa {
        width: 24px;
        line-height: 1.6;
        vertical-align: top;
    }
    table {
        line-height: 1.6;
        max-width: 100% !important;
        table-layout: fixed;
        width: 100% !important;
    }
    td {
        vertical-align: top;
        word-wrap: break-word;
        &:nth-child(1) {
            width: 25px;
        }
    }
}

.page-title {
    background: #333;
    color: $color_5;
    .site-container {
        padding-bottom: 0;
        padding-top: 0;
    }
}

.full-post {
    img {
        width: 100%;
        height: auto;
    }
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    p {
        margin: 15px 0;
    }
}

.list-inline {
    list-style: none outside none;
    padding: 0px;
}

.comments {
    float: right;
}

#page-portfolio {
    h2 {
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
    }
}

.pagination {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;

    li {
        float: left;
        background: none !important;
        padding: 0 5px !important;
    }
    li.active {
        a {
            color: $color_1;
        }
    }
    li.disabled .fa {
        color: $color_1;
    }
}

#page-about-us {
    .column-one-quarter {
        float: none;
        display: inline-block;
        vertical-align: top;
        padding: 2%;
        width: 24%;
        img {
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            -khtml-border-radius: 50%;
            width: 100%;
            vertical-align: middle;
        }
    }
    .circ-img-wrap {
        width: 70%;
        display: inline-block;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -khtml-border-radius: 50%;
        border: 4px solid transparent;
        box-shadow: 0px 0px 5px #888;
        -moz-box-shadow: 0px 0px 5px #888;
        -webkit-box-shadow: 0px 0px 5px #888;
        background-clip: padding-box;
        -webkit-background-clip: padding-box;
    }
    .team {
        border-bottom: 1px solid #ccc;
        padding: 0 0 20px;
        margin: 0px 0 25px;
        text-align: center;
        h4 {
            color: $color_1;
            padding-top: 15px;
        }
    }
    h2 {
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
        text-align: center;
    }
}

#page-cv {
    h2 {
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
        text-align: center;
    }
}

.polaroid {
    padding: 20px 20px 10px;
    background: white;
    box-shadow: 0 0 5px #888;
    -moz-box-shadow: 0 0 5px #888;
    -webkit-box-shadow: 0 0 5px #888;
    -ms-transform: rotate(2deg);
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
    margin-bottom: 40px;
    h2 {
        border: none !important;
    }
    img {
        width: 100%;
    }
}

.cv-personal-info {
    display: block;
}

.cv-container {
    float: left;
    padding-left: 5%;
    ul {
        line-height: 1.6;
        margin-top: 0;
        padding: 0;
    }
    h4 {
        color: $color_1;
    }
    .sub-title {
        h4 {
            float: left;
            color: $color_1;
        }
        span {
            float: right;
            font-style: italic;
            color: $color_8;
        }
    }
    i {
        padding-right: 5px;
    }
}

#footer {
    background: #222;
    color: $color_5;
    height: 45px;
    .site-container {
        padding: 0px 20px;
        display: table;
        width: 100%;
        height: 100%;
    }
    p {
        display: table-cell;
        padding: 0px 0;
        margin: 0;
        box-sizing: border-box;
        vertical-align: middle;
    }
    .fa {
        width: 30px;
        font-size: 20px;
        background: white;
        color: $color_9;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        -khtml-border-radius: 50%;
        height: 30px;
        box-sizing: border-box;
        padding: 5px 0 0 6px;
        margin: 2px 2px 0;
        transition: 0.4s;
        &:hover {
            color: $color_5;
            background: $orange;
        }
    }
    .fa-facebook {
        padding-left: 8px;
        padding-top: 6px;
    }
    .fa-rss {
        padding-left: 8px;
        padding-top: 5px;
    }
    .fa-envelope {
        padding-left: 5px;
    }
}

.footer-social-container {
    display: table-cell;
    vertical-align: middle;
    padding: 0px;
    box-sizing: border-box;
    margin: 0;
}

.footer-social {
    float: right;
}

.avatar {
    position: absolute;
    width: 60px;
    img {
        width: 100%;
    }
}

#comments {
    padding-top: 20px;
    ul {
        padding: 0px;
        margin: 0px;
        list-style: none outside none;
        li {
            ul {
                li {
                    ul {
                        li {
                            padding: 23px 0 0;
                        }
                    }
                    padding: 23px 0px 0px 30px;
                    margin: 0px;
                }
            }
        }
    }
    li {
        padding: 0px 0px 23px;
        list-style: none outside none;
    }
}

.comment_right {
    display: table;
}

.comment_info {
    padding-bottom: 7px;
    span {
        padding: 0px 12px;
    }
}

#blog-comment {
    input {
        display: block;
        border: 2px solid #ccc;
        background: #fff;
        padding: 5px 5px 5px 5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    textarea {
        width: 100%;
        max-width: 100%;
        border: 2px solid #ccc;
        height: 100px;
        margin-bottom: 10px;
        padding: 5px 5px 5px 5px;
        box-sizing: border-box;
    }
    .btn {
        border: none;
        cursor: pointer;
        color: $color_5;
        background: $orange;
        text-align: center;
        width: 60px;
        float: right;
    }
}

.comment-reply-form {
    margin: 30px 0px 10px 80px;
    background: #E6E6E6;
    padding: 20px 20px 10px;
    input {
        display: block;
        border: 2px solid #ccc;
        background: #fff;
        padding: 5px 5px 5px 5px;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
    }
    textarea {
        width: 100%;
        max-width: 100%;
        border: 2px solid #ccc;
        height: 100px;
        margin-bottom: 10px;
        padding: 5px 5px 5px 5px;
        box-sizing: border-box;
    }
    .btn {
        border: none;
        cursor: pointer;
        color: $color_5;
        background: $orange;
        text-align: center;
        width: 60px;
        float: right;
    }
}

.reply-posted-spinner {
    display: none;
}

.name-container {
    width: 47.9%;
    position: relative;
    display: inline-block;
    margin-right: 3.5%;
}

.email-container {
    width: 47.9%;
    position: relative;
    display: inline-block;
}

.subject-container {
    position: relative;
}

.message-container {
    position: relative;
}

.btn {
    border: medium none;
    cursor: pointer;
    color: $color_10;
    background: none repeat scroll 0% 0% $orange;
    text-align: center;
    width: 60px;
    padding: 5px;
    opacity: 1;
    transition: opacity .3s;

    &--big {
        font-size: 21px;
        padding: 5px 20px;
    }

    &:hover {
        opacity: .85;
    }
}

.comment-background {
    background: #E6E6E6;
    padding: 20px 20px 10px;
}

.comment-right {
    background: #E6E6E6;
    padding: 20px 20px 10px 20px;
    margin-left: 80px;
    position: relative;
    margin-bottom: 5px;
}

.comment-deleted {
    background: #E6E6E6;
    padding: 20px 20px 10px 20px;
    margin-left: 80px;
    position: relative;
    margin-bottom: 5px;
    opacity: 0.2;
    p {
        margin: 0;
    }
}

.share-social {
    padding-right: 10px;
}

.share-twitter {
    width: 30px !important;
    margin: -3px 0px 0 6px;
    display: inline-block;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
}

.share-facebook {
    width: 30px !important;
    margin: -3px 0px 0 6px;
    display: inline-block;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
}

.comment-date {
    color: $color_11;
    padding-left: 20px;
    float: right;
}

.comment-border {
    border-top: 1px solid #ccc;
    padding: 10px 0 0;
}

.reply {
    float: right;
    padding: 5px 5px 0;
    float: none;
    padding: 0;
    text-align: right;
}

.link {
    float: right;
    padding: 5px 5px 0;
    float: none;
    padding: 0;
    text-align: right;
}

.comment-info {
    & + p {
        margin: 0;
        padding: 15px 0;
        word-wrap: break-word;
    }
}

.left-arrow-container-outer {
    position: absolute;
    display: inline-block;
    width: 15px;
    height: 15px;
    left: -15px;
}

.left-arrow-container-inner {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
}

.left-arrow {
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    line-height: 0;
    border: 15px solid transparent;
    margin-left: -13px;
    border-right: 15px solid #E6E6E6;
    left: 0px;
    top: 0px;
}

ul[data-level="0"] {
    > li {
        > div[id^="comment-"] {
            > .comment-right {
                background: #F2F2F2;
                .left-arrow {
                    border-right: 15px solid #F2F2F2;
                }
                .comment-border {
                    .rating {
                        background-color: $background_color_1;
                    }
                }
            }
            > .comment-background {
                background: #F2F2F2;
            }
        }
    }
}

.tags {
    margin-top: 30px;
}

.tagtoggle {
    font-weight: bold;
}

.timespan {
    font-size: 100%;
    float: right;
    color: $color_11;
}

.asterisk_input {
    &:after {
        content: " *";
        color: $orange;
        position: absolute;
        font-size: xx-large;
        top: 20px;
        right: 6px;
    }
}

.form-group {
    position: relative;
}

[id^='comment-'] {
    ul.comment_ul {
        display: none;
    }
}

.rating {
    form {
        display: none !important;
    }
    margin: 0px 10px 0px 0px !important;
    display: inline-block;
    float: none;
}

.reply-link-container {
    float: right;
}

.show-replies {
    float: left;
}

.score {
    padding: 0 5px;
}

.clearfix {
    &:before {
        content: "";
        display: table;
    }
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    zoom: 1;
}

@media only screen and (max-width: 768px) {
    .column-two-thirds {
        width: 100%;
        border: none;
        padding: 0;
    }
    .column-two-thirds-right {
        width: 100%;
        border: none;
        padding: 0;
    }
    .column-one-third-left {
        width: 100%;
        border: none;
        padding: 0;
    }
    .column-one-third {
        width: 100%;
        border: none;
        padding: 0;
    }
    .column-one-half {
        width: 100%;
        border: none;
        padding: 0;
    }
    .column-one-quarter {
        width: 100%;
        border: none;
        padding: 0;
    }
    #section-parallax {
        background-size: cover !important;
        background-attachment: scroll !important;
        background-position: center center;
    }
    #section-parallax-2 {
        background-size: cover !important;
        background-attachment: scroll !important;
        background-position: center center;
        height: 100%;
    }
    #section-site-map {
        .logicon-info {
            width: 45%;
            padding-right: 5%;
            p {
                width: 100%;
            }
        }
        .contact-info {
            width: 27.5%;
        }
        .popular-pages {
            width: 27.5%;
        }
    }
    .col-4 {
        width: 47.5%;
    }
    .col-3 {
        width: 47.5%;
    }
    .col-2 {
        width: 47.5%;
    }
    .col-xs-2 {
        width: 47.5%;
    }
    .social-fonts-container {
        width: 47.5%;
        float: left;
    }
    .contact-section-info {
        width: 47.5%;
        float: left;
        word-wrap: break-word;
    }
    .menu-mobile-container {
        display: block;
        height: 70px;
    }
    #menu {
        display: none;
    }
    #menu-mob {
        height: 70px;
    }
    .header-container {
        h1 {
            position: absolute;
            top: 13px;
        }
    }
    #page-about-us {
        .column-one-quarter {
            width: 49%;
        }
    }
    .input_id_email {
        // display: block;
        // width: 100%;
        // margin-right: 0;
    }
    .input_id_name {
        display: block;
        width: 100%;
        margin-right: 0;
    }
}

@media only screen and (max-width: 550px) {
    .header-container {
        h1 {
            font-size: 23px;
        }
    }
    .slide-body {
        h2 {
            font-size: 8px ! important;
        }
        .caption {
            font-size: 6px ! important;
        }
    }
    #section-site-map {
        .logicon-info {
            width: 100%;
            padding-right: 0%;
        }
        .contact-info {
            width: 50%;
        }
        .popular-pages {
            width: 50%;
        }
    }
    .col-4 {
        width: 100%;
    }
    .col-3 {
        width: 100%;
    }
    .col-2 {
        width: 100%;
    }
    .col-xs-2 {
        width: 47.5%;
    }
    #footer {
        p {
            display: none;
        }
    }
    #page-about-us {
        .column-one-quarter {
            width: 100%;
            padding: 2%;
        }
    }
    .comment-date {
        display: none;
    }
    .show-replies {
        display: block;
        float: none;
    }
    .name-container {
        width: 100%;
    }
    .email-container {
        width: 100%;
    }
}
.level-badge {
    width: 73px;
    height: 73px;
    margin: 0 auto;
    transition: 0.4s;
}
